<template>
  <section class="Event">
    <!-- 页面 header 通屏大图 -->
    <FullBanner :bannerURL="$route.meta.img" :moduleName="'JCI Organizations'">
      <template v-slot:customFill>
        <iq-card class="iq-card">
          <section class="container">
            <el-row class="py-3">
              <el-col :span="24">
                <el-input clearable @clear="getChannelList" @keyup.enter.native="getChannelList" class="w-100"
                  placeholder="Search Organization" v-model="input"></el-input>
              </el-col>
            </el-row>
            <el-row :gutter="5" class="pb-3">
              <!-- <el-col :span="4">
                <el-select @change="getChannelList" clearable @clear="getChannelList" v-model="type"
                  :placeholder="$t('Channels.2c4@type')">
                  <el-option :value="1" label="Project">{{
                    $t("Channels.463@project")
                    }}</el-option>
                  <el-option :value="2" label="Event">Event</el-option>
                  <el-option :value="3" label="Course">Course</el-option>
                  <el-option :value="4" label="Meeting">Meeting</el-option>
                  <el-option :value="11" label="Partner">Partners</el-option>
                  <el-option :value="12" label="Sponsor">Sponsor</el-option>
                  <el-option :value="13" label="Merchant">Merchant</el-option>
                  <el-option :value="21" label="JCI">JCI</el-option>
                  <el-option :value="22" label="Area">Area</el-option>
                  <el-option :value="23" label="National">National</el-option>
                  <el-option :value="24" label="Regional">Regional</el-option>
                  <el-option :value="25" label="Local">Local</el-option>
                </el-select>
              </el-col>
              <el-col :span="4">
                <el-select clearable @clear="getChannelList" v-model="country_id" @change="getChannelList"
                  :placeholder="$t('Channels.01d@country')" filterable >
                  <el-option v-for="(v, i) in countryList" :key="i" :label="v.name" :value="v.id">
                  </el-option>
                </el-select>
              </el-col> -->
              <el-col :sm="24" :md="16">
                <OrganazationSel @getOrganizationParams="getOrganizationParams" />
              </el-col>
            </el-row>
          </section>
        </iq-card>
      </template>
    </FullBanner>
    <div class="container">
      <b-row v-if="loading">
        <b-col v-for="(item, index) in 12" :key="index" md="6" lg="4">
          <el-skeleton animated>
            <template slot="template">
              <iq-card body-class="p-0" style="border-radius: 5px;overflow: hidden;">
                <el-skeleton-item variant="image" style="width: 100%; height: 100px;" />
                <div style="padding: 20px;" class="d-flex align-items-center">
                  <el-skeleton-item variant="circle" style="width: 50px; height: 50px;" />
                  <div class="ml-3 d-flex flex-column">
                    <el-skeleton-item variant="text" style="width: 100px;" />
                    <el-skeleton-item variant="text" style="width: 60px;margin-top: 10px;" />
                  </div>
                </div>
                <div style="padding: 0 20px 22px;">
                  <el-skeleton-item variant="button" style="width: 100%;height: 35px" />
                  <el-skeleton-item variant="text" style="width: 120px;" />
                </div>
              </iq-card>
            </template>
          </el-skeleton>
        </b-col>
      </b-row>

      <b-row v-if="channelList.length&&!loading">
        <b-col v-for="(item, index) in channelList" :key="index" md="6" lg="4">
          <ChannelItem :channel="item" />
        </b-col>
      </b-row>

      <div v-if="channelList.length" class="d-flex justify-content-end container">
        <el-pagination background :pager-count="3" layout="prev, pager, next" :total="total" :page-size="size"
          @current-change="handleCurrentChange" hide-on-single-page>
        </el-pagination>
      </div>

      <b-row v-else class="d-flex justify-content-center" style="background:#fff;margin-top: 60px">
        <img style="width:250px;" src="@/assets/images/group/empty1.png" alt=""  v-if="!loading"/>
      </b-row>
    </div>
  </section>
</template>
<script>
import FullBanner from '@/components/ComComponents/FullBanner'
import ChannelItem from './components/ChannelItem'
import Screen from '@/components/ComComponents/Screen'
import OrganazationSel from '@/components/ComComponents/OrganazationSel'
export default {
  components: { FullBanner, ChannelItem, Screen, OrganazationSel },
  created () {
    this.getChannelList()
    this.getAddress()
    this.$bus.$on('updateChannel', () => {
      this.getChannelList()
    })
  },
  data () {
    return {
      channelList: [],
      countryList: [],
      total: 0,
      page: 1,
      size: 12,
      type: '',
      input: '',
      country_id: '',
      organization_id: '',
      loading: true
    }
  },

  methods: {
    // 筛选
    getOrganizationParams (data) {
      let OrganData = JSON.parse(JSON.stringify(data))
      this.type = OrganData.level.id
      let level = OrganData.level.id
      switch (level) {
        case 0: {
          // jci
          this.organization_id = OrganData.jci.id
          this.getChannelList()
          break
        }
        case 4: {
          // area
          this.organization_id = OrganData.area.id
          this.getChannelList()
          break
        }
        case 2: {
          // nation
          this.organization_id = OrganData.national.id
          this.getChannelList()
          break
        }
        case 3: {
          // region
          this.organization_id = OrganData.regional.id
          this.getChannelList()
          break
        }
        case 1: {
          // local
          this.organization_id = OrganData.local.id
          this.getChannelList()
          break
        }
      }
    },
    // 获取国家列表
    async getAddress (level = 2) {
      const params = {
        level
      }
      let { status, data } = await this.$http.getAddress(params)
      if (status == 200) {
        this.countryList = data
      }
    },
    handleCurrentChange (val) {
      this.page = val
      this.getChannelList()
    },
    getChannelList (val) {
      this.loading = true
      this.$http
        .getChannelList({
          keyword: this.input,
          type: this.type,
          per_page: this.size,
          // country_id: this.country_id,
          organization_id: this.organization_id,
          page: this.page
        })
        .then((res) => {
          this.channelList = res.data.data
          this.total = res.data.total
          setTimeout(() => {
            this.loading = false
          }, 0)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .Event {
    min-height: 100vh;

    ::v-deep .el-date-editor--date .el-input__inner,
    ::v-deep .el-select .el-input__inner,
    ::v-deep .el-input__inner {
      height: 45px;
      border-radius: 11px;
    }
  }
</style>
