<template>
  <el-card class="card-det">
    <div class="top-bg-image">
      <img
        @click="
          $router.push({
            path: '/organization-detail',
            query: { channel_id: channel.channel_id,id: channel.id },
          })
        "
        :src="backgroundImg"
      />
    </div>
    <section class="card-body">
      <div
        class="detail"
        @click="
          $router.push({
            path: '/organization-detail',
            query: { channel_id: channel.channel_id,id: channel.id },
          })
        "
      >
        <div class="iq-card-avatar-self">
          <!-- <img :src="channel.image" alt="" /> -->
          <el-avatar
            :size="50"
            :src="channel.image"
            @error="()=>{return true}"
          >
            <img
              src="@/assets/images/public/circle_jci.png"
            />
          </el-avatar>
        </div>
        <div class="detail-text" style="cursor: pointer">
          <div class="title">{{ channel.title }}</div>
          <div class="area">{{ channel.type | typeFilter }}</div>
        </div>
        <!-- {{ channel }} -->
      </div>
      <div>
        <b-button
          type="submit"
          v-if="channel.is_follow == false"
          class="d-block w-100 mb-2 mt-3"
          style="background-color: #50b5ff; border-color: #50b5ff"
          @click="channelFollow(channel.channel_id, 1, channel.id)"
        >
          {{ $t("Channels.26d@follow") }}
        </b-button>
        <b-button
          type="submit"
          v-if="channel.is_follow == true"
          class="btn d-block w-100 mb-2 mt-3"
          @click="channelFollow(channel.channel_id, 2, channel.id)"
        >
          {{ $t("Channels.074@unfollow") }}
        </b-button>
      </div>
      <!-- 小头像 -->
      <div class="d-flex align-items-center pl-2">
        <div
          class="iq-media-group d-flex align-items-center"
          v-for="(item, index) in channel.avatar"
          :key="index"
        >
          <a href="#" class="iq-media">
            <img class="img-fluid rounded-circle" :src="item.avatar" />
          </a>
        </div>
        <div class="textDesc ml-2">
          <span style="color: #999"> {{ channel.desc }}</span>
        </div>
      </div>
    </section>
  </el-card>
</template>
<script>
export default {
  name: 'Online',
  components: {},
  props: {
    channel: {
      type: Object
    }
  },

  computed: {
    backgroundImg () {
      let background = this.channel.cover
      return background || require('@/assets/images/page-img/JVCProfileCoverPhoto.jpg')
    }
    // avatarImg () {
    //   let img = this.channel.image;
    //   console.log;
    //   console.log(555555, img ? img : require('@/assets/images/public/user.png'));
    //   return img ? img : require('@/assets/images/public/user.png')
    // }

  },
  data () {
    // 这里存放数据
    return {

    }
  },
  filters: {
    typeFilter (val) {
      let type = { 1: 'Project', 2: 'Event', 3: 'Course', 4: 'Meeting', 11: 'Partner', 12: 'Sponners', 13: 'Merchant', 14: 'Teacher', 21: 'JCI', 22: 'Area', 23: 'National', 24: 'Regional', 25: 'Local' }
      return type[val]
    }
  },

  methods: {
    channelFollow (channel_id, type, organization_id ) {
      this.$http.channelFollow({ channel_id, type, organization_id }).then((res) => {
        if (res.status == 200) {
          this.$bus.$emit('updateChannel')
        }
      })
    }

  }
}
</script>
<style src='' lang='scss' scoped>
::v-deep .el-card__body {
  padding: 0px;
}
.card-det {
  height: 280px;
  margin-bottom: 15px;

  .top-bg-image {
    height: 100px;
    > img {
      display: block;
      width: 100%;
      height: 100%;
      // object-fit: cover;
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .detail {
      text-align: left;
      display: flex;
      .iq-card-avatar-self {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 15px;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .detail-text {
        width: calc(100% - 65px);
        .title {
          font-size: 17px;
          color: #333333;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .group-member {
    img {
      width: 24px;
      height: 24px;
    }
  }

  .desc {
    font-size: 12px;
    color: #999999;
    line-height: 26px;
    text-align: left;
  }
  .attentionActive {
    color: #fff;
    font-size: 14px;
    background-color: #50b5ff;
  }
  .attention {
    color: #fff;
    font-size: 14px;
    background-color: #666;
  }
  // 小头像
  .iq-media {
    width: 24px;
    height: 24px;
    margin-left: -12px !important;
    img {
      width: 100%;
    }
  }
}
</style>
